@import 'cores/mixin'

// Color
$primary: #4B61DD
$brand: #179E63
$black: #232933
$blue: #4B61DD
$border: #E2E2E2
$muted: #B4B4B4

// $text: #545465
$text: #626B79
$text-light: #545465
$text-dark: #2B2A35
$link: #334AC0
$white: #FFF
$mekarios: #5B37D4


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/font'
@import 'cores/base'


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/header-revamp'
@import 'components/footer'
@import 'components/buttons'
@import 'components/form'
@import 'components/general'


/* ------------------------------------------------------------------------------------------------ */
/* Page style */
/* ------------------------------------------------------------------------------------------------ */

@import 'pages/blog'
@import 'pages/job-desc'
@import 'pages/feature'
@import 'pages/home'
@import 'pages/about'